import axios from "axios";
import { Notification } from "element-ui";
import VueRouter from "../src/router";
import { getBaseUrlToEnv } from "../src/api/config.js";
const service = axios.create({
  baseURL: getBaseUrlToEnv(),
  // baseURL:"http://8.131.93.154:18181/jeecg-boot",
  // baseURL : 'http://192.168.1.40:18181/jeecg-boot',
  // baseURL : 'http://192.168.1.168:18181/jeecg-boot',
  timeout: 10000,
});

// 配置请求拦截
service.interceptors.request.use(
  (config) => {
    // console.log(config);
    // 添加token
    config.headers["X-Access-Token"] = localStorage.getItem("token");
    return config;
  },
  (error) => {
    // alert("发送请求失败");
  }
);

// 配置响应拦截
service.interceptors.response.use(
  (res) => {
    // if (res.status != 500) {
    return res;
    // } else {
    //   alert(res.data.msg);
    //   window.location.href = "/";
    // }
  },
  (error) => {
    if (error.response.status != 500) {
      return error;
    } else {
      if (!error.config.skip) {
        Notification.error({
          title: "登录失效",
          message: "请重新登录",
        });
      }
      console.log(VueRouter);
      //   window.location.href = "/";
      VueRouter.push("/");
      return error;
    }
  }
);

export default service;
