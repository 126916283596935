const DOMAIN_ENUM = {
    'develop': 'http://192.168.1.40:18181/jeecg-boot', //开发版
    'dev':'http://192.168.1.168:18181/jeecg-boot',//测试环境
    'pre': 'http://8.131.93.154:18181/jeecg-boot', //预发
    'release': 'https://www.mingxintong.cn/jeecg-boot',//正式版
}
export const ENV = 'release' // 开发：develop  生产：release

export const getBaseUrlToEnv = () => {
    return DOMAIN_ENUM[ENV]
}
