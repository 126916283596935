import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import $axios from '../utils/axios';
import hasPermission from '../utils/hasPermission'

Vue.config.productionTip = false
Vue.prototype.$axios = $axios
Vue.use(hasPermission)
if (process.env.NODE_ENV !== 'development') {
  //如果是生产环境  直接将log写没
  console.log = () => {}
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
